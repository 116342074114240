import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import "./Products.css";

const Products = ({ title, homeLabel }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("ALL");

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const renderCategory = (filter, maxItems) => {
    const filteredProducts = products?.filter((item) => {
      if (filter === "ALL") {
        return true;
      } else {
        return item?.category?.includes(filter);
      }
    });

    if (filteredProducts && filteredProducts.length > 0) {
      return (
        <div className="popular-games">
          <div className="games-list">
            {filteredProducts.slice(0, maxItems).map((item, index) => (
              <div
                key={index}
                className="game"
                onClick={() => navigate(`/product/${item?.name}`)}
              >
                <img
                  src={`https://theevilstore.in/${item?.image}`}
                  alt="pro-img"
                />
                {item?.hotTag && <span className="hottag">{item?.hotTag}</span>}
                <h5>{item?.name}</h5>
                {item?.stock === "No" && (
                  <span className="outofstock">Out of stock</span>
                )}
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="products-container">
      <div className="explore-products-container">
        <div className="filter-btns">
          <button
            onClick={() => setFilter("ALL")}
            className={`${filter === "ALL" && "active"}`}
          >
            ALL
          </button>
          {products?.filter((item) => item.category.includes("Fast Process"))
            .length > 0 && (
            <button
              onClick={() => setFilter("Fast Process")}
              className={`${filter === "Fast Process" && "active"}`}
            >
              Fast Process
            </button>
          )}
          {products?.filter((item) => item.category.includes("Slow Process"))
            .length > 0 && (
            <button
              onClick={() => setFilter("Slow Process")}
              className={`${filter === "Slow Process" && "active"}`}
            >
              Slow Process
            </button>
          )}
          {products?.filter((item) => item.category.includes("BGMI")).length >
            0 && (
            <button
              onClick={() => setFilter("BGMI")}
              className={`${filter === "BGMI" && "active"}`}
            >
              BGMI
            </button>
          )}
          {products?.filter((item) => item.category.includes("PUBG")).length >
            0 && (
            <button
              onClick={() => setFilter("PUBG")}
              className={`${filter === "PUBG" && "active"}`}
            >
              PUBG
            </button>
          )}
          {products?.filter((item) => item.category.includes("Via Login"))
            .length > 0 && (
            <button
              onClick={() => setFilter("Via Login")}
              className={`${filter === "Via Login" && "active"}`}
            >
              Via Login
            </button>
          )}
          {products?.filter((item) => item.category.includes("Premium"))
            .length > 0 && (
            <button
              onClick={() => setFilter("Premium")}
              className={`${filter === "Premium" && "active"}`}
            >
              Premium
            </button>
          )}
          {products?.filter((item) => item.category.includes("Voucher"))
            .length > 0 && (
            <button
              onClick={() => setFilter("Voucher")}
              className={`${filter === "Voucher" && "active"}`}
            >
              Voucher
            </button>
          )}
          {products?.filter((item) => item.category.includes("Others")).length >
            0 && (
            <button
              onClick={() => setFilter("Others")}
              className={`${filter === "Others" && "active"}`}
            >
              Others
            </button>
          )}
        </div>

        {renderCategory(filter, 100)}
      </div>
    </div>
  );
};

export default Products;
