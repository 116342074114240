const IMAGES = {
  banner1: require("../img/home/banner1.jpg"),
  banner2: require("../img/home/banner2.jpg"),
  banner3: require("../img/home/banner3.jpeg"),
  phonepe: require("../img/phonepe.png"),
  paytm: require("../img/paytm.png"),
  gpay: require("../img/gpay.png"),
  upi: require("../img/upi.webp"),
  logo: require("../img/logo.png"),
};

export default IMAGES;
